import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import type { GatsbyBrowser } from 'gatsby'

const queryClient = new QueryClient()

// eslint-disable-next-line import/prefer-default-export
export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
}) => <QueryClientProvider client={queryClient}>{element}</QueryClientProvider>

import { createTheme, responsiveFontSizes } from '@mui/material/styles'

const mainColor = '#17304e'
const secondaryColor = '#03bfcb'
const highlightColor = '#05B8C6'

const defaultTheme = responsiveFontSizes(
  createTheme({
    typography: {
      subtitle1: {
        fontSize: '32px',
        fontWeight: 400,
        lineHeight: '1.2',
        letterSpacing: '0px',
      },
      body2: {
        fontSize: '22px',
        fontWeight: 700,
        lineHeight: '1.43',
        letterSpacing: '0px',
      },
    },
    palette: {
      primary: {
        main: mainColor,
      },
      secondary: {
        main: secondaryColor,
      },
      info: {
        main: highlightColor,
      },
    },
    components: {
      MuiTypography: {
        variants: [
          {
            props: { variant: 'caption' },
            style: {
              fontFamily: 'Roboto',
              fontSize: '20px',
              fontWeight: 700,
              lineHeight: '1.2',
              letterSpacing: '0px',
              textAlign: 'left',
            },
          },
        ],
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            fontSize: '18px',
            lineHeight: '1.4',
            fontWeight: 400,
            textTransform: 'uppercase',
            color: mainColor,
          },
        },
      },
      MuiCardActionArea: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        defaultProps: {
          disableRipple: true,
        },
        variants: [
          {
            props: { variant: 'text', color: 'primary' },
            style: {
              border: '1px solid #9FD2DF',
              fontSize: '18px',
              fontWeight: 900,
              lineHeight: '36px',
              letterSpacing: '0px',
              paddingLeft: '12px',
              paddingRight: '12px',
              borderRadius: '18px',
            },
          },
          {
            props: { variant: 'contained', color: 'secondary' },
            style: {
              background: secondaryColor,
              color: 'rgba(64,64,64)',
              fontSize: '18px',
              fontWeight: 500,
              lineHeight: 1,
            },
          },
          {
            props: { variant: 'contained', color: 'primary' },
            style: {
              background:
                '-webkit-linear-gradient( -34deg, rgb(3,191,203) 0%, rgb(30,73,123) 100%)',
              boxShadow: '8.5px 9.8px 18px rgba(0, 0, 0, 0.22)',
              color: 'white',
              fontSize: '21px',
              fontWeight: 900,
              lineHeight: '1.75',
              padding: '14px 36px',
              borderRadius: '18px',
              '&:hover': {
                background:
                  '-webkit-linear-gradient( -34deg, rgba(3,191,203, 0.8) 0%, rgba(30,73,123, 0.8) 100%)',
                boxShadow: '8.5px 9.8px 18px rgba(0, 0, 0, 0.12)',
              },
            },
          },
          {
            props: { variant: 'contained', color: 'primary', size: 'medium' },
            style: {
              background:
                '-webkit-linear-gradient( -34deg, rgb(3,191,203) 0%, rgb(30,73,123) 100%)',
              boxShadow: '8.5px 9.8px 18px rgba(0, 0, 0, 0.22)',
              color: 'white',
              '&:hover': {
                background:
                  '-webkit-linear-gradient( -34deg, rgba(3,191,203, 0.8) 0%, rgba(30,73,123, 0.8) 100%)',
                boxShadow: '8.5px 9.8px 18px rgba(0, 0, 0, 0.12)',
              },
              fontSize: '18px',
              fontWeight: 900,
              lineHeight: '36px',
              letterSpacing: '0px',
              padding: '6px 8px',
              borderRadius: '18px',
            },
          },
        ],
      },
    },
  })
)

export default defaultTheme

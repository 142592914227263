exports.components = {
  "component---src-components-blog-list-blog-list-category-tsx": () => import("./../../../src/components/BlogList/BlogListCategory.tsx" /* webpackChunkName: "component---src-components-blog-list-blog-list-category-tsx" */),
  "component---src-components-blog-list-blog-list-tsx": () => import("./../../../src/components/BlogList/BlogList.tsx" /* webpackChunkName: "component---src-components-blog-list-blog-list-tsx" */),
  "component---src-components-blog-page-blog-page-tsx": () => import("./../../../src/components/BlogPage/BlogPage.tsx" /* webpackChunkName: "component---src-components-blog-page-blog-page-tsx" */),
  "component---src-components-portfolio-page-portfolio-page-tsx": () => import("./../../../src/components/PortfolioPage/PortfolioPage.tsx" /* webpackChunkName: "component---src-components-portfolio-page-portfolio-page-tsx" */),
  "component---src-components-service-page-service-page-tsx": () => import("./../../../src/components/ServicePage/ServicePage.tsx" /* webpackChunkName: "component---src-components-service-page-service-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */)
}

